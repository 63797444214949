import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import * as Faicons from 'react-icons/ai';
import * as MaIcons from "react-icons/md";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import logo from "../resources/images/Logo1.png";
import logos from "../resources/images/Logo1s.png";


export default function Sidebar({children}){
    const[isOpen, setIsOpen] = useState(true);
    const toggle= () => {
        setIsOpen (!isOpen);
        sessionStorage.setItem("activo", !isOpen);
        console.log("test"+ sessionStorage.getItem("activo"));
        //location.reload(false);
    } 

    const menuItem=[
        {
            path:"/Home",
            name:"Home",
            icon:<Faicons.AiOutlineHome size={25} className="iconos"/>
        },
        {
            path:"/Emissions",
            name:"Emissions",
            icon:<MaIcons.MdSensors size={25} className="iconos" />
        },
        {
            path:"/Directory",
            name:"Supplier Directory",
            icon:<BiIcons.BiSitemap size={25} className="iconos" />
        },
        {
            path:"/Dashboard",
            name:"Dashboards",
            icon:<Faicons.AiOutlineDashboard size={25} className="iconos" />
        },
        {
            path:"/Knowledge",
            name:"Knowledge",
            icon:<BsIcons.BsBook size={25} className="iconos" />
        }
    ]
    const menuItem1=[
        {
            path:"/Home",
            name:"Home",
            icon:<Faicons.AiOutlineHome size={25} className="iconos"/>
        },
        {
            path:"/Emissions",
            name:"Emissions",
            icon:<MaIcons.MdSensors size={25} className="iconos" />
        }
    ]
    return(
            <div className="sidebar" style={{width: isOpen? "18rem":"5rem"}}>
                    
            {
                isOpen?(
                    <img src={logo} style={{ height: 70, margin: 12, marginTop:40, marginBottom:40}} />
                ):(
                    <img src={logos} style={{ height: 70, margin: 12, marginTop:60, marginBottom:20}} />
                )
            }   
            {
                menuItem.map((item,index)=>(
                    <NavLink to={item.path} key={index} className="link" activeClassName="active"> 
                    <div className="icon">{item.icon}</div>
                     <div className="link_text" style={{display: isOpen? "block":"none"}}>{item.name}</div>
                     </NavLink>
                ))
            }
            
            {
                /*
                 menuItem1.map((item,index)=>(
                            <NavLink to={item.path} key={index} className="link" activeClassName="active"> 
                            <div className="icon">{item.icon}</div>
                            <div className="link_text" style={{display: isOpen? "block":"none"}}>{item.name}</div>
                            </NavLink>
                        ))
                <div className="footer" style={{  width: "17rem"}}>
                                <div className="texto_side">Settings</div> 
                                <div className="texto_side">Terms & Condition</div> 
                                <div className="flex">
                                    <div className="circle">
                                        <MaIcons.MdArrowBackIos size={17} className="iconos-f" onClick={toggle}/>
                                    </div>
                                    <div className="link_text_down">Collapse Menu</div> 
                                </div>
                            </div>*/
                isOpen?(
                    <>
                        <div className="footer" style={{  width: "17rem"}}>
                                <div className="texto_side">Settings</div> 
                                <div className="texto_side">Terms & Condition</div> 
                                <div className="flex">
                                    <div className="circle">
                                        <MaIcons.MdArrowBackIos size={17} className="iconos-f" onClick={toggle}/>
                                    </div>
                                    <div className="link_text_down">Collapse Menu</div> 
                                </div>
                            </div>                   
                    </>
                ):(
                    <>
                    <div className="footer" style={{ padding:"0.1rem 1.5rem"}}>
                        <div className="circle">
                             <MaIcons.MdArrowForwardIos size={17}  className="iconos-f" onClick={toggle}/>
                        </div>
                    </div>
                    </>
                )
            }
            <main>{children}</main>
        </div>
        
        
    )
}