import * as React from 'react';
import { Grid } from '@mui/material';
import logo from "../../resources/images/Logo2.png";
import * as BsIcons from "react-icons/bs";
import * as Imicons from "react-icons/im";
import * as Faicons from 'react-icons/ai';
import * as MaIcons from "react-icons/md";


/*Imicons.ImLeaf
BsIcons.BsHeadset
MaIcons.MdOutlineMoreVert*/
export default function Home(){
    
    return(
        
        <div className="content" style={{paddingLeft: '50px', paddingRight: '50px'}}>
           <h1 className="titulo">Home</h1>
             <div class="row">
                <div class="col-md-6">
                    <div className="card mb-3">
                        <div className="card-body imagenesm" >
                            <h1 className="h1p white ">welcome to Novagrids</h1>
                            <h2 className="h2p white abajo">Sustainability Intelligence</h2>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 ">
                <div className="card mb-3 sear">
                        <div className="card-body sear">
                            <Grid container spacing={2} >
                                <Grid className='mrgporc'>
                                    <img src={logo} style={{ height: 50, margin: 15,}} />
                                </Grid>
                            </Grid>
                            <h2 className="h2p">Search your supplier</h2>
                            <div className='searchp'>
                                <h2 className="h2p topp grey">Company / Supplier / Division</h2> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div className="card mb-3 ">
                        <div className="card-body pri">
                            <Grid container spacing={2} >
                                <Grid className='gridm topp2'>
                                     <h1 className="h1p white mrgporm">Manage your emissions</h1>
                                     <h2 className="h2p white mrgporm">Take control of your emissions</h2>
                                </Grid>
                                <Grid className='topp2'>
                                     <MaIcons.MdSensors size={70} className="iconosm"/>
                                </Grid>
                            </Grid>
                            <Grid  spacing={2} className="abajom">
                                <Grid className='access'>
                                    <h3 className="h3p white">Access your emissions data</h3>
                                </Grid>
                                <Grid>
                                    <BsIcons.BsPlusCircleFill size={25} className="iconosm"/>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                <div className="card mb-3 ">
                        <div className="card-body das">
                            <Grid container spacing={2} >
                                <Grid className='gridd topp3'>
                                    <h1 className="hd white">Manage your Dashboards</h1>
                                </Grid>
                                <Grid className='topp2'>
                                    <Faicons.AiOutlineDashboard size={70} className="iconosm"/>
                                </Grid>
                            </Grid>
                            <Grid  spacing={2} className="abajod">
                                <Grid className='access2'>
                                    <h3 className="h3p white">Create dashboard</h3>
                                </Grid>
                                <Grid>
                                    <BsIcons.BsPlusCircleFill size={25} className="iconosm"/>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div className="card mb-3">
                        <div className="card-body imagenese" >
                           <h1 className="h1p white">Let us know if you need help</h1>
                           <h2 className="h2p white">Our team of experts will guide you</h2>
                           <Grid  spacing={2} className="abajod">
                                <Grid className='access4'>
                                    <h3 className="h3p white">Contact Us</h3>
                                </Grid>
                                <Grid>
                                    <BsIcons.BsHeadset size={25} className="iconosm"/>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                <div className="card mb-3">
                        <div className="card-body imagenesh" >
                            <h1 className="h1p white">Explore decarbonization</h1>
                            <h2 className="h2p white">Your path to NetZero</h2>
                            <Grid  spacing={2} className="abajod">
                                <Grid className='access3'>
                                    <h3 className="h3p white">Find Initiatives</h3>
                                </Grid>
                                <Grid>
                                    <Imicons.ImLeaf size={25} className="iconosm"/>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
             </div>

        </div>
    )
}