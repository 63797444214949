import { useParams,Link} from 'react-router-dom'
import Navbar from '../Navbar'
const userID=10
/*
<TextField type="search" size="small" placeholder="Buscar" className="inputser" InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <IoIcons.IoSearchCircleSharp size={25} style={{color:'#4285F4'}} />
                            </InputAdornment>
                        ),
                        }}/>
*/
export default function App3(){
    const params = useParams()
    // muesta el valor con el nombre de la varible que pongo en el app ejemplo {id: '20'}
    console.log(params)
    const {id} = useParams()


    return(
        <div>
            <h1>test link</h1>
            <Link to="/App2">test enlace</Link>
            <br/>
            <Link to={`/App2/${userID}`} >test enlace con get</Link>
            <br/>
            <h1>url {params.id} --- {id}</h1>
        <Navbar/>
        </div>
        
    )
}