import * as React from 'react';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import logo from "../../resources/images/Logo1.png";
import InputAdornment from '@mui/material/InputAdornment';
import * as IoIcons from "react-icons/io5";
import * as BsIcons from "react-icons/bs";
import * as BiIcons from "react-icons/bi";
import Graficos1 from './Graficos1';
import Graficos2 from './Graficos2';
import Graficos3 from './Graficos3';
import Graficos4 from './Graficos4';
import Graficos5 from './Graficos5';
import Graficos6 from './Graficos6';
import Graficos7 from './Graficos7';
import Graficos8 from './Graficos8';
import Graficos9 from './Graficos9';
import Graficos10 from './Graficos10';
import Graficos11 from './Graficos11';
import Graficos12 from './Graficos12';
import Graficos13 from './Graficos13';
import Graficos14 from './Graficos14';
import Graficos15 from './Graficos15';
import Button from '@mui/material/Button';



export default function Dashboard(){
    const [imagen, setImagen] = React.useState(true);
    const [graficos1, setGraficos1] = React.useState(false);
    const [graficos2, setGraficos2] = React.useState(false);
    const [graficos3, setGraficos3] = React.useState(false);
    const [graficos4, setGraficos4] = React.useState(false);
    const [graficos5, setGraficos5] = React.useState(false);
    const [graficos6, setGraficos6] = React.useState(false);
    const [graficos7, setGraficos7] = React.useState(false);
    const [graficos8, setGraficos8] = React.useState(false);
    const [graficos9, setGraficos9] = React.useState(false);
    const [graficos10, setGraficos10] = React.useState(false);
    const [graficos11, setGraficos11] = React.useState(false);
    const [graficos12, setGraficos12] = React.useState(false);
    const [graficos13, setGraficos13] = React.useState(false);
    const [graficos14, setGraficos14] = React.useState(false);
    const [graficos15, setGraficos15] = React.useState(false);
    const [div, setDiv] = React.useState('');
    const [pro, setPro] = React.useState('');
    const [tra, setTra] = React.useState('');

  const handleChange = (event) => {
        var div = event.target.value;

        /*if(op==1){
            setImagen(true);
            setGraficos1(false);
            setGraficos2(false);
        }else if(op==2){
            setImagen(false);
            setGraficos1(true);
            setGraficos2(false);
        }else{
            setImagen(false);
            setGraficos1(false);
            setGraficos2(true);
        }*/

        /*if(op==1){
            setPro(1);
            setTra(1);
            setImagen(true);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(false);
        }
        setDiv(event.target.value);*/

        if(div==2){
            setImagen(false);
            setGraficos1(true);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(false);
        }else if(div==3){
            setImagen(false);
            setGraficos1(false);
            setGraficos2(true);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(false);
        }else if(div==4){
            setImagen(false);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(true);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(false);
        }else if(div==5){
            setImagen(false);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(true);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(false);
        }else if(div==6){
            setImagen(false);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(true);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(false);
        }else if(div==7){
            setImagen(false);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(true);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(false);
        }else if(div==8){
            setImagen(false);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(true);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(false);
        }else if(div==9){
            setImagen(false);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(true);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(false);
        }else if(div==10){
            setImagen(false);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(true);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(false);
        }else if(div==11){
            setImagen(false);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(true);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(false);
        }else if(div==12){
            setImagen(false);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(true);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(false);
        }else if(div==13){
            setImagen(false);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(true);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(false);
        }else if(div==14){
            setImagen(false);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(true);
            setGraficos14(false);
            setGraficos15(false);
        }else if(div==15){
            setImagen(false);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(true);
            setGraficos15(false);
        }else if(div==16){
            setImagen(false);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(true);
        }else{
            setImagen(true);
            setGraficos1(false);
            setGraficos2(false);
            setGraficos3(false);
            setGraficos4(false);
            setGraficos5(false);
            setGraficos6(false);
            setGraficos7(false);
            setGraficos8(false);
            setGraficos9(false);
            setGraficos10(false);
            setGraficos11(false);
            setGraficos12(false);
            setGraficos13(false);
            setGraficos14(false);
            setGraficos15(false);
        }

        setDiv(event.target.value);
  };
  const handleChange1 = (event) => {
    setPro(event.target.value);
  };
  const handleChange2 = (event) => {
    setTra(event.target.value);
  };
  const handleSubmit = () => {

    if(div==2){
        setImagen(false);
        setGraficos1(true);
        setGraficos2(false);
        setGraficos3(false);
        setGraficos4(false);
        setGraficos5(false);
        setGraficos6(false);
        setGraficos7(false);
        setGraficos8(false);
        setGraficos9(false);
        setGraficos10(false);
        setGraficos11(false);
        setGraficos12(false);
        setGraficos13(false);
        setGraficos14(false);
        setGraficos15(false);
    }else if(div==3){
        setImagen(false);
        setGraficos1(false);
        setGraficos2(true);
        setGraficos3(false);
        setGraficos4(false);
        setGraficos5(false);
        setGraficos6(false);
        setGraficos7(false);
        setGraficos8(false);
        setGraficos9(false);
        setGraficos10(false);
        setGraficos11(false);
        setGraficos12(false);
        setGraficos13(false);
        setGraficos14(false);
        setGraficos15(false);
    }else if(div==4){
        setImagen(false);
        setGraficos1(false);
        setGraficos2(false);
        setGraficos3(true);
        setGraficos4(false);
        setGraficos5(false);
        setGraficos6(false);
        setGraficos7(false);
        setGraficos8(false);
        setGraficos9(false);
        setGraficos10(false);
        setGraficos11(false);
        setGraficos12(false);
        setGraficos13(false);
        setGraficos14(false);
        setGraficos15(false);
    }else if(div==5){
        setImagen(false);
        setGraficos1(false);
        setGraficos2(false);
        setGraficos3(false);
        setGraficos4(true);
        setGraficos5(false);
        setGraficos6(false);
        setGraficos7(false);
        setGraficos8(false);
        setGraficos9(false);
        setGraficos10(false);
        setGraficos11(false);
        setGraficos12(false);
        setGraficos13(false);
        setGraficos14(false);
        setGraficos15(false);
    }else if(div==6){
        setImagen(false);
        setGraficos1(false);
        setGraficos2(false);
        setGraficos3(false);
        setGraficos4(false);
        setGraficos5(true);
        setGraficos6(false);
        setGraficos7(false);
        setGraficos8(false);
        setGraficos9(false);
        setGraficos10(false);
        setGraficos11(false);
        setGraficos12(false);
        setGraficos13(false);
        setGraficos14(false);
        setGraficos15(false);
    }else if(div==7){
        setImagen(false);
        setGraficos1(false);
        setGraficos2(false);
        setGraficos3(false);
        setGraficos4(false);
        setGraficos5(false);
        setGraficos6(true);
        setGraficos7(false);
        setGraficos8(false);
        setGraficos9(false);
        setGraficos10(false);
        setGraficos11(false);
        setGraficos12(false);
        setGraficos13(false);
        setGraficos14(false);
        setGraficos15(false);
    }else if(div==8){
        setImagen(false);
        setGraficos1(false);
        setGraficos2(false);
        setGraficos3(false);
        setGraficos4(false);
        setGraficos5(false);
        setGraficos6(false);
        setGraficos7(true);
        setGraficos8(false);
        setGraficos9(false);
        setGraficos10(false);
        setGraficos11(false);
        setGraficos12(false);
        setGraficos13(false);
        setGraficos14(false);
        setGraficos15(false);
    }else if(div==9){
        setImagen(false);
        setGraficos1(false);
        setGraficos2(false);
        setGraficos3(false);
        setGraficos4(false);
        setGraficos5(false);
        setGraficos6(false);
        setGraficos7(false);
        setGraficos8(true);
        setGraficos9(false);
        setGraficos10(false);
        setGraficos11(false);
        setGraficos12(false);
        setGraficos13(false);
        setGraficos14(false);
        setGraficos15(false);
    }else if(div==10){
        setImagen(false);
        setGraficos1(false);
        setGraficos2(false);
        setGraficos3(false);
        setGraficos4(false);
        setGraficos5(false);
        setGraficos6(false);
        setGraficos7(false);
        setGraficos8(false);
        setGraficos9(true);
        setGraficos10(false);
        setGraficos11(false);
        setGraficos12(false);
        setGraficos13(false);
        setGraficos14(false);
        setGraficos15(false);
    }else if(div==11){
        setImagen(false);
        setGraficos1(false);
        setGraficos2(false);
        setGraficos3(false);
        setGraficos4(false);
        setGraficos5(false);
        setGraficos6(false);
        setGraficos7(false);
        setGraficos8(false);
        setGraficos9(false);
        setGraficos10(true);
        setGraficos11(false);
        setGraficos12(false);
        setGraficos13(false);
        setGraficos14(false);
        setGraficos15(false);
    }else if(div==12){
        setImagen(false);
        setGraficos1(false);
        setGraficos2(false);
        setGraficos3(false);
        setGraficos4(false);
        setGraficos5(false);
        setGraficos6(false);
        setGraficos7(false);
        setGraficos8(false);
        setGraficos9(false);
        setGraficos10(false);
        setGraficos11(true);
        setGraficos12(false);
        setGraficos13(false);
        setGraficos14(false);
        setGraficos15(false);
    }else if(div==13){
        setImagen(false);
        setGraficos1(false);
        setGraficos2(false);
        setGraficos3(false);
        setGraficos4(false);
        setGraficos5(false);
        setGraficos6(false);
        setGraficos7(false);
        setGraficos8(false);
        setGraficos9(false);
        setGraficos10(false);
        setGraficos11(false);
        setGraficos12(true);
        setGraficos13(false);
        setGraficos14(false);
        setGraficos15(false);
    }else if(div==14){
        setImagen(false);
        setGraficos1(false);
        setGraficos2(false);
        setGraficos3(false);
        setGraficos4(false);
        setGraficos5(false);
        setGraficos6(false);
        setGraficos7(false);
        setGraficos8(false);
        setGraficos9(false);
        setGraficos10(false);
        setGraficos11(false);
        setGraficos12(false);
        setGraficos13(true);
        setGraficos14(false);
        setGraficos15(false);
    }else if(div==15){
        setImagen(false);
        setGraficos1(false);
        setGraficos2(false);
        setGraficos3(false);
        setGraficos4(false);
        setGraficos5(false);
        setGraficos6(false);
        setGraficos7(false);
        setGraficos8(false);
        setGraficos9(false);
        setGraficos10(false);
        setGraficos11(false);
        setGraficos12(false);
        setGraficos13(false);
        setGraficos14(true);
        setGraficos15(false);
    }else if(div==16){
        setImagen(false);
        setGraficos1(false);
        setGraficos2(false);
        setGraficos3(false);
        setGraficos4(false);
        setGraficos5(false);
        setGraficos6(false);
        setGraficos7(false);
        setGraficos8(false);
        setGraficos9(false);
        setGraficos10(false);
        setGraficos11(false);
        setGraficos12(false);
        setGraficos13(false);
        setGraficos14(false);
        setGraficos15(true);
    }else{
        setImagen(true);
        setGraficos1(false);
        setGraficos2(false);
        setGraficos3(false);
        setGraficos4(false);
        setGraficos5(false);
        setGraficos6(false);
        setGraficos7(false);
        setGraficos8(false);
        setGraficos9(false);
        setGraficos10(false);
        setGraficos11(false);
        setGraficos12(false);
        setGraficos13(false);
        setGraficos14(false);
        setGraficos15(false);
    }

    };
    
    return(
            <div className="content" style={{paddingLeft: '50px', paddingRight: '50px'}}> 
               <h1 className="titulo">Dashboard</h1>
               
                <Grid container style={{marginTop: '20px'}}>
                    <Grid container xs={7} style={{backgroundColor: '#4285F4', padding: '20px', borderRadius: '10px'}} direction="row" alignItems="center" justifyContent="center">
                        <Grid item xs={2} style={{textAlign: 'center'}}>
                            <span style={{fontSize: '20px'}} className='white'>Customer</span>
                        </Grid>
                        <Grid item xs={6}>
                            <Select className='selectp' id="division" value={div} onChange={handleChange} inputProps={{ 'aria-label': 'Without label' }} style={{width:'100%'}} >
                                <MenuItem value={1}></MenuItem>
                                <MenuItem value={2}>Min 1 - Div 1</MenuItem>
                                <MenuItem value={3}>Min 1 - Div 2</MenuItem>
                                <MenuItem value={4}>Min 1 - Div 3</MenuItem>
                                <MenuItem value={5}>Min 1 - Div 4</MenuItem>
                                <MenuItem value={6}>Min 1 - Div 5</MenuItem>
                                <MenuItem value={7}>Min 1 - Div 6</MenuItem>
                                <MenuItem value={8}>Min 1 - Div 7</MenuItem>
                                <MenuItem value={9}>Prov 1</MenuItem>
                                <MenuItem value={10}>Prov 2</MenuItem>
                                <MenuItem value={11}>Prov 3</MenuItem>
                                <MenuItem value={12}>Prov 4</MenuItem>
                                <MenuItem value={13}>Prov 5</MenuItem>
                                <MenuItem value={14}>Transp 1</MenuItem>
                                <MenuItem value={15}>Transp 2</MenuItem>
                                <MenuItem value={16}>Transp 3</MenuItem>
                            </Select>
                       </Grid>
                       <Grid item xs={4} style={{textAlign: 'center'}}>
                        <Button
                            fullWidth
                            color="primary"
                            style={{
                                    width: "80%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background:"white",
                                    color: "grey",
                                    alignItems: "center !important",
                            }}
                            onClick={handleSubmit}
                        >
                            Search <IoIcons.IoSearchCircleSharp size={25} style={{color:'#4285F4'}} />
                        </Button>
                       </Grid>
                    </Grid>
                    <Grid container xs={5} style={{paddingLeft: '4px', paddingRight: '4px'}} direction="row" alignItems="center" justifyContent="center">
                        <Grid xs={6} style={{paddingLeft: '4px', paddingRight: '4px'}}>
                            <Grid item xs={12} className="opm1 " >
                                <BsIcons.BsShareFill size={17} style={{color:'#ffffff',marginTop:"3%"}} />
                                <h4 className='h4p white'>Share</h4>
                            </Grid>
                            <Grid item xs={12} className="opm2 " >
                            <TextField
                                id="date"
                                label="Start"
                                type="date"
                                size="small"
                                defaultValue="2022-05-01"
                                sx={{ width: 150,height:45, marginTop:'5%' }}
                                InputLabelProps={{
                                shrink: true,
                                }}
                            />
                            </Grid>
                        </Grid>
                        <Grid xs={6} style={{paddingLeft: '4px', paddingRight: '4px'}}>
                            <Grid item xs={12} className="opm1 " > 
                                <BiIcons.BiPlus size={20} style={{color:'#ffffff',marginTop:"2%"}} />
                                <h4 className='h4p white'>Add dashboard</h4>
                            </Grid>
                            <Grid item xs={12} className="opm2 " >
                                <TextField
                                    id="date"
                                    label="End"
                                    type="date"
                                    size="small"
                                    defaultValue="2022-05-01"
                                    sx={{ width: 150,height:45, marginTop:'5%' }}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
               
               {/*<Grid xs={12} container >
                   <Grid xs={7} className='busq'>
                        <Grid item xs={4} style={{marginTop:'6%'}}>
                            <h3 className='h3p tser white'>Customer</h3>
                        </Grid>
                        <Grid item xs={6} style={{marginTop:'5%'}}>
                            <Select className='selectp' id="division" value={div} onChange={handleChange} inputProps={{ 'aria-label': 'Without label' }} style={{width:'100%'}} >
                                <MenuItem value={1}></MenuItem>
                                <MenuItem value={2}>Min 1 - Div 1</MenuItem>
                                <MenuItem value={3}>Min 1 - Div 2</MenuItem>
                                <MenuItem value={4}>Min 1 - Div 3</MenuItem>
                                <MenuItem value={5}>Min 1 - Div 4</MenuItem>
                                <MenuItem value={6}>Min 1 - Div 5</MenuItem>
                                <MenuItem value={7}>Min 1 - Div 6</MenuItem>
                                <MenuItem value={8}>Min 1 - Div 7</MenuItem>
                                <MenuItem value={9}>Prov 1</MenuItem>
                                <MenuItem value={10}>Prov 2</MenuItem>
                                <MenuItem value={11}>Prov 3</MenuItem>
                                <MenuItem value={12}>Prov 4</MenuItem>
                                <MenuItem value={13}>Prov 5</MenuItem>
                                <MenuItem value={14}>Transp 1</MenuItem>
                                <MenuItem value={15}>Transp 2</MenuItem>
                                <MenuItem value={16}>Transp 3</MenuItem>
                            </Select>
                       </Grid>
                       <Grid item xs={4} style={{marginTop:'5%', textAlign: 'center'}}>
                        <Button
                            fullWidth
                            color="primary"
                            style={{
                                    width: "60%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    background:"white",
                                    color: "grey",
                                    alignItems: "center !important",
                            }}
                            onClick={handleSubmit}
                        >
                                Search <IoIcons.IoSearchCircleSharp size={25} style={{color:'#4285F4'}} />
                        </Button>
                       </Grid>
                   </Grid>
                   <Grid xs={2} className="divp1" >
                        <Grid item xs={12} className="opm1 " >
                            <BsIcons.BsShareFill size={17} style={{color:'#ffffff',marginTop:"3%"}} />
                            <h4 className='h4p white'>Share</h4>
                        </Grid>
                        <Grid item xs={12} className="opm2 " >
                        <TextField
                            id="date"
                            label="Start"
                            type="date"
                            size="small"
                            defaultValue="2022-05-01"
                            sx={{ width: 150,height:45, marginTop:'5%' }}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                        </Grid>
                   </Grid>
                   <Grid xs={2} className="divp1 " >
                        <Grid item xs={12} className="opm1 " > 
                            <BiIcons.BiPlus size={20} style={{color:'#ffffff',marginTop:"2%"}} />
                            <h4 className='h4p white'>Add dashboard</h4>
                        </Grid>
                        <Grid item xs={12} className="opm2 " >
                            <TextField
                                id="date"
                                label="End"
                                type="date"
                                size="small"
                                defaultValue="2022-05-01"
                                sx={{ width: 150,height:45, marginTop:'5%' }}
                                InputLabelProps={{
                                shrink: true,
                                }}
                            />
                        </Grid>
                   </Grid>
               </Grid>*/}
               {
                   imagen? (
                    <Grid conteiner spacing={2} className="pato"  >
                        <Grid className='mrgporm'>
                            <img src={logo} style={{ height: 100, margin: 15,}} />
                        </Grid>
                    </Grid>
                   ):("")
                }
                {
                    graficos1? (
                    <Graficos1 />
                   ):("")
                }
                {
                    graficos2? (
                    <Graficos2 />
                   ):("")
                }
                {
                    graficos3? (
                    <Graficos3 />
                   ):("")
                }
                {
                    graficos4? (
                    <Graficos4 />
                   ):("")
                }
                {
                    graficos5? (
                    <Graficos5 />
                   ):("")
                }
                {
                    graficos6? (
                    <Graficos6 />
                   ):("")
                }
                {
                    graficos7? (
                    <Graficos7 />
                   ):("")
                }
                {
                    graficos8? (
                    <Graficos8 />
                   ):("")
                }
                {
                    graficos9? (
                    <Graficos9 />
                   ):("")
                }
                {
                    graficos10? (
                    <Graficos10 />
                   ):("")
                }
                {
                    graficos11? (
                    <Graficos11 />
                   ):("")
                }
                {
                    graficos12? (
                    <Graficos12 />
                   ):("")
                }
                {
                    graficos13? (
                    <Graficos13 />
                   ):("")
                }
                {
                    graficos14? (
                    <Graficos14 />
                   ):("")
                }
                {
                    graficos15? (
                    <Graficos15 />
                   ):("")
                }
            </div>  
    )
}