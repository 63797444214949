import React from "react"
import {Route, Routes, Navigate, Outlet} from 'react-router-dom'
import Login from './components/pages/Login'
import Dashboard from './components/pages/Dashboard'
import App3 from './components/pages/App3'
import NotFoundPage from './components/pages/NotFoundPage'
import './resources/css/navbar.scss'
import Home from './components/pages/Home'
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import Emissions from './components/pages/Emissions'
import Directory from './components/pages/Directory'
import Knowledge from './components/pages/Knowledge'
import 'bootstrap/scss/bootstrap.scss'

//import 'bootstrap/dist/css/bootstrap.min.css';

const SidebarLayout = () => (
    <>
        
        <div className="flex">
            <Sidebar />
            <div className="content">
            <Navbar/>
            <Outlet />
            </div>
        </div>
        
    </>
  );
export default function App(){
    return(

        
        <Routes>
            <Route path='/Login' element={<Login/>} />
            <Route path="*" element={<NotFoundPage/>} />
            <Route element={<SidebarLayout/>}>
                <Route exact path='/Home' element={<Home/>} />
                <Route path='/' element={<Navigate to='/Login'/>} />
                <Route path='/Dashboard' element={<Dashboard/>} />
                <Route path='/Emissions' element={<Emissions/>} />
                <Route path='/Directory' element={<Directory/>} />
                <Route path='/Knowledge' element={<Knowledge/>} />
                <Route path='/App3' element={<App3/>} />
                <Route path='/App3/:id' element={<App3/>} />
                <Route path='/App33' element={<Navigate to='/App3'/>} />  //redireccion
            </Route>
        </Routes>
        

    )
}