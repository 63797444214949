import * as React from 'react';
import {useState} from "react";
import { Grid } from '@mui/material';
import * as BsIcons from "react-icons/bs";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { CategoryScale, LinearScale, BarElement,Title } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import * as IoIcons from "react-icons/io5";
import { NavLink } from "react-router-dom";
import logo from "../../resources/images/Logo2.png";
import mapa from "../../resources/images/mapa.JPG";
import Button from '@mui/material/Button';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';


/*BsIcons.BsCircleFill
BsIcons.BsThreeDotsVertical*/
ChartJS.register(ArcElement, Tooltip, Legend,CategoryScale,LinearScale,BarElement,Title);


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginBottom:'1%',
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));
const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#34dabc',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginBottom:'1%',
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));
export default function Graficos1(){
    const handleSubmit = (event) => {};
   //pie chart
    const data = {
        labels: ['S1', 'S2', 'S3'],
        datasets: [
          {
            label: '# of Votes',
            data: [18.80, 9.61, 11.55],
            backgroundColor: [
              'rgb(66, 133, 244)',
              'rgb(80, 115, 151)',
              'rgb(52, 197, 186)',
            ],
            borderColor: [
              'rgb(255, 255, 255)',
              'rgb(255, 255, 255)',
              'rgb(255, 255, 255)',
            ],
            borderWidth: 1,
          },
        ],
      };
      // barras
      const options = {
        responsive: true,
        plugins: {
          legend: {
            display: false ,
          },
         
        },
      };

      const data2 = {
        labels : ['Div1', 'Div2'],
        datasets: [
          {
            data: [0.2, 1.9],
            backgroundColor: 'rgb(66, 133, 244)',
          },
          {
            data: [0.5, 1],
            backgroundColor: 'rgb(52, 197, 186)',
          },
        ],
      };

      // bar colores
      const options2 = {
        plugins: {
            legend: {
                display: true ,
              },
        },
        responsive: true,
        interaction: {
          mode: 'index' ,
          intersect: false,
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };
      //const labels3 = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Ago','Sep','Oct','Nov','Dec'];
      const data3 = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Ago','Sep','Oct','Nov','Dec'],
        datasets: [
          {
            label: 'Scope 1',
            data: [2299629, 2328479, 1082913, 876745, 118714, 1060025, 1470951, 891310, 739040, 2207838, 2376896, 2343875],
            backgroundColor: 'rgb(52, 197, 186)',
          },
          {
            label: 'Scope 2',
            data: [1045902, 1173579, 773906, 1317845, 755674, 607751, 311746, 648773, 1359079, 410114, 708875, 496345],
            backgroundColor: 'rgb(186, 218, 252)',
          },
          {
            label: 'Scope 3',
            data: [1109534, 1162744, 1309126, 1213996, 896764, 634440, 785873, 962024, 932274, 893042, 898048, 752230],
            backgroundColor: 'rgb(66, 133, 244)',
          },
        ],
      };

    return(
        <div>
           <Box className="box">
             {
               //linea 1
             }
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Item >
                    <Grid item xs={12} className="flex">
                      <h4 className='textes'>Total Emissions</h4>
                      <div>
                        <BsIcons.BsThreeDotsVertical size={17} />
                      </div>
                    </Grid>
                    <Grid item xs={12} className="flex">
                        <h4 className='ht1'>39.96</h4>
                        <h4 className='ht2'>CO<sub>2</sub>EQ</h4>
                    </Grid>
                  </Item>
                </Grid>
                <Grid item xs={3}>
                  <Item>
                    <Grid item xs={12} className="flex">
                      <Grid item xs={11}><h4 className='textes'>Scope 1</h4></Grid>
                      <Grid item xs={1}><BsIcons.BsThreeDotsVertical size={17} /></Grid>
                    </Grid>
                    <Grid item xs={12} className="flex">
                        <h4 className='ht1'>18.80</h4>
                        <h4 className='ht2'>CO<sub>2</sub>EQ</h4>
                    </Grid>
                  </Item>
                </Grid>
                <Grid item xs={3}>
                  <Item>
                    <Grid item xs={12} className="flex">
                      <Grid item xs={11}><h4 className='textes'>Scope 2</h4></Grid>
                      <Grid item xs={1}><BsIcons.BsThreeDotsVertical size={17} /></Grid>
                    </Grid>
                    <Grid item xs={12} className="flex">
                        <h4 className='ht1'>9.61</h4>
                        <h4 className='ht2'>CO<sub>2</sub>EQ</h4>
                    </Grid>
                  </Item>
                </Grid>
                <Grid item xs={3}>
                  <Item>
                    <Grid item xs={12} className="flex">
                      <Grid item xs={11}><h4 className='textes'>Scope 3</h4></Grid>
                      <Grid item xs={1}><BsIcons.BsThreeDotsVertical size={17} /></Grid>
                    </Grid>
                    <Grid item xs={12} className="flex">
                        <h4 className='ht1'>11.55</h4>
                        <h4 className='ht2'>CO<sub>2</sub>EQ</h4>
                    </Grid>
                  </Item>
                </Grid>
                <Grid conteiner item xs={9} >
                  <Grid conteiner item xs={12} className="flex" style={{marginBottom:'1%'}}>
                    <Grid item xs={4}>
                      <Item style={{marginRight:'1.5%',height:'100%'}}>
                        <Grid item xs={12} className="flex">
                          <Grid item xs={11}><h4 className='textes'>Total Emissions</h4></Grid>
                          <Grid item xs={1}><BsIcons.BsThreeDotsVertical size={17} /></Grid>
                        </Grid>
                        <Grid item xs={12} >
                          <Pie data={data} />
                        </Grid>
                      </Item>
                    </Grid>
                    <Grid  item xs={8} >
                      <Grid item xs={12} className="flex">
                        <Grid item xs={6} style={{marginBottom:'1%'}}>
                          <Item style={{marginRight:'1.5%', height:'100%'}}>
                            <Grid item xs={12} className="flex">
                              <Grid item xs={11}><h4 className='textes'>Emissions by Divissions</h4></Grid>
                              <Grid item xs={1}><BsIcons.BsThreeDotsVertical size={17} /></Grid>
                            </Grid>
                            <Grid item xs={12} >
                              <Bar options={options} data={data2} />
                            </Grid>
                          </Item>
                        </Grid>
                        <Grid item xs={6} style={{marginBottom:'1%'}}>
                          <Item style={{marginLeft:'1.5%',height:'100%'}}>
                            <Grid item xs={12} className="flex" >
                              <Grid item xs={11}><h4 className='textes'>Industrial Benchmark</h4></Grid>
                              <Grid item xs={1}><BsIcons.BsThreeDotsVertical size={17} /></Grid>
                            </Grid>
                            <Grid item xs={12} >
                              <Bar options={options} data={data2} />
                            </Grid>
                          </Item>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{height:'42%'}}>
                        <Item style={{height:'100%'}}>
                          <Grid item xs={12}>
                            <h4 className='textes3'>Sustainable Intelligence</h4>
                            <h4 className='textes2'>* Produce inaccurate results <NavLink to="">Link</NavLink></h4>
                            <h4 className='textes2'>* Consumes time and generates costs <NavLink to=""> Web link</NavLink></h4>
                            <h4 className='textes2'>* Generates reporting risks <NavLink to="">Link</NavLink> </h4>
                            <h4 className='textes2'>* Static and difficult to track</h4>
                          </Grid>
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="flex">
                    <Grid item xs={8}>
                      <Item style={{height:'100%'}}>
                        <Grid item xs={12} className="flex">
                          <Grid item xs={11}><h4 className='textes'>Total Emissions</h4></Grid>
                          <Grid item xs={1}><BsIcons.BsThreeDotsVertical size={17} /></Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Bar options={options2} data={data3} />
                        </Grid>
                      </Item>
                    </Grid>
                    <Grid item xs={4} >
                      <Item2 style={{marginLeft:'1.5%',height:'100%'}} >
                        <Grid item xs={12} className="flex">
                          <Grid item xs={11}><img src={logo} style={{ height: 60, margin: 15, marginBottom:'30%',marginTop:'10%'}} /></Grid>
                          <Grid item xs={1}><BsIcons.BsThreeDotsVertical size={17} /></Grid>
                        </Grid>
                        <Grid item xs={12} >
                          <h2 className="h2p " style={{marginBottom:"5%"}}>Search your sup</h2>
                          <Button
                          fullWidth
                          color="primary"
                          style={{
                                  width: "90%",
                                  fontSize:"0.7rem",
                                  marginLeft: "5%",
                                  marginRight: "auto",
                                  marginBottom:"10%",
                                  background:"white",
                                  color: "grey",
                                  alignItems: "center !important",
                          }}
                          onClick={handleSubmit}
                          >
                            Company / Supplier / Division <IoIcons.IoSearchCircleSharp size={25} style={{color:'#507397'}} />
                          </Button>
                        </Grid>
                      </Item2>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Item style={{height:'100%'}}>
                    <Grid item xs={12} className="flex">
                      <Grid item xs={11}></Grid>
                      <Grid item xs={1}><BsIcons.BsThreeDotsVertical size={17} /></Grid>
                    </Grid>
                    <Grid item xs={12} className="flex ">
                      <img src={mapa} style={{height: 440}} />
                    </Grid>
                  </Item>
                </Grid>
              </Grid>
            </Box>
            
        </div>
        

    )
}