import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import logo from "../../resources/images/Logo1.png";
import fondo from "../../resources/images/motanas.png";
import { Grid } from '@mui/material';
import '../../resources/css/login.css'
import { useNavigate } from 'react-router-dom';





export default function Login() {
  
  const navigate= useNavigate()
  const [textFieldValue, setTextFieldValue] = React.useState('');

  document.body.style.background = `url(${fondo}) no-repeat 100%  `;
  const handleSubmit = () => {
    //document.body.style.background = '#ffffff no-repeat 100%';
    if(textFieldValue == 'Nova2022'){
      navigate('/Home');
    }
  };

  const _handleTextFieldChange = (e) => {
    setTextFieldValue(e.target.value);
  };

  return (
      <div className="main"  >
         <div className="contenido"  > 
          <Grid container spacing={2} >
              <Grid style={{ textAlign:'center', fontWeight:"bold", }}>
                <Typography component="h1" variant="h5" style={{color:"#507397",marginTop:70,marginLeft:10 }}>
                  INICIAR SESIÓN
                </Typography>
              </Grid>
              <Grid>
                  <img src={logo} style={{ height: 75, margin: 12, marginTop:50}} />
              </Grid>
          </Grid>
          <Typography component="h1" variant="h6" style={{color:"#507397",marginTop:40}}>
            Correo electrónico
          </Typography>
          <form id="login-form">
            <TextField 
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <br/>
            <br/>
            <Typography component="h1" variant="h6" style={{color:"#507397",}}>
            Contraseña
          </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={_handleTextFieldChange}
            />
            <br/>
            <div style={{
                   
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign:"center",
            }}>
            <Button
            fullWidth
            color="primary"
            style={{
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 30,
                    marginBottom:50,
                    background:
                      "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
                    color: "#fff",
                    alignItems: "center !important",
            }}
            onClick={handleSubmit}
          >
            Iniciar sesión 
          </Button>
            </div>
            </form>
          </div>
      </div>

  );
}