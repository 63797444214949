import {NavLink} from 'react-router-dom'
import * as BsIcons from "react-icons/bs";

const Navbar=()=>{
    return(
        <div className='navbarp' style={{width: '100%', paddingLeft: '50px', paddingRight: '50px'}}>
            <NavLink to='/Login' clas><BsIcons.BsPersonCircle size={30} className="iconos" />Sign off</NavLink> 
        </div>
    )

}

export default Navbar